// String replace
// ========================================
@function str-replace($string, $search, $replace: ""){
	$index: str-index($string, $search);
	@if $index{
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}
// Generate font-face
// ========================================
@mixin get-font($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg){
	$src: null;
	$extmods:(
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);
	$formats:(
		otf: "opentype",
		ttf: "truetype"
	);
	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}
	@font-face{
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}
// make transition animation
@mixin transition($end_count , $delay_time) {
	@for $i from 1 through $end_count {
		&:nth-child(#{$i}) {
		transition-delay: #{$i * $delay_time}s ;
		}
	}
}