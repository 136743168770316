
/* Color */
$cwhite: #fff;
$cblack: #000;
$cdark: #2d313e;
$cbase: #000;
$cmain: #777;
$cread: #a3a3a3;
$cgray: #ccc;
$cline: #eee;
$clight: #fafafa;
$cinfo: #5bc0de;
$cwarning: #f0ad4e;
$cdanger: #d9534f;
$csuccess: #5cb85c;
$cform: #f8fafb;
$ccorp: #fd6574;

//ADD

$mfc: rgba(#fff, 0.5);
$cpurple: #66739d;
$textColor: rgba(#fff, 0.8);


$cfacebook: #1877f2;
$cinstagram: #845ddb;
$ctwitter: #00acee;
$cyoutube: #c4302b;
$clinkedin: #0077b5;
$cwhatsapp: #25d366;
$cviber: #675ca8;

$cpdf: #bd0f17;
$cword: #1b56b4;
$cexcel: #18753f;
$cpowerpoint: #bc3922;
$carchive: #fdd977;
/* Color */

/* Border radius */
$bdrn: 0px;
$bdrs: 3px;
$bdrm: 5px;
$bdrl: 10px;
$bdrp: 50%;
/* Border radius */

/* Font family */
$ffbase: "roboto";
$ffmain: "roboto";