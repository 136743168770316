@import "../../index.scss";
.adminWrap{
    .login_form{
        .form_title{
            margin-bottom: 30px;
            font-size: 24px;
            color: $cwhite;
        }
    }
    .admin_menu_wrap{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba($cwhite, 0.5);
        .admin_menu{
            .navigation{
                ul{
                    @extend .no-list;
                    display: flex;
                    li{
                        margin-right: 30px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            color: $cwhite;
                            font-size: 20px;
                            &.active{
                                color: $ccorp;
                            }
                        }
                    }
                }
            }
        }
        .logout{
            display: flex;
            justify-content: flex-end;
            span{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $cwhite;
                background-color: $ccorp;
                font-size: 14px;
                padding: 5px 10px;
                border-radius: 5px;
                transition: 0.3s;
                cursor: pointer;
                &:hover{
                    background-color: darken($ccorp, 15);
                }
            }
        }
    }
    .adminAddExp{
        .form_title{
            margin-bottom: 30px;
            font-size: 24px;
            color: $cwhite;
        }
    }
}
.form_wrap{
    .form_items{
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        .form_item{
            input,
            textarea{
                height: 50px;
                background-color: $cwhite;
                width: 70%;
                border-radius: 5px;
                padding: 10px 15px;
            }
            input{

            }
            textarea{
                height: 100px;
            }
            .gilaki{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 180px;
                height: 50px;
                border-radius: 5px;
                color: $cwhite;
                background-color: $ccorp;
                cursor: pointer;
                &:hover{
                    background-color: darken($ccorp, 20);
                }
            }
        }
    }
}

main.light{
    .adminWrap{
        .login_form{
            .form_title{
                color: $cbase;
            }
        }
        .admin_menu_wrap{
            border-bottom: 1px solid rgba($cdark, 0.5);
            .admin_menu{
                .navigation{
                    ul{
                        li{
                            a{
                                color: $cmain;
                                font-size: 20px;
                                &.active{
                                    color: $cbase;
                                }
                            }
                        }
                    }
                }
            }
        }
        .adminAddExp{
            .form_title{
                color: $cmain;
            }
        }
    }
    .form_wrap{
        .form_items{
            .form_item{
                input,
                textarea{
                    height: 50px;
                    background-color: $cmain;
                    width: 70%;
                    border-radius: 5px;
                    padding: 10px 15px;
                    &::placeholder{
                        color: $cwhite;
                    }
                }
                input{
    
                }
                textarea{
                    height: 100px;
                }
            }
        }
    }
}