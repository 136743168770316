@import "../../index.scss";
div.left_section_navigation{
    padding-top: 75px;
    @media(max-width: 1680px){
        padding-top: 70px;
    }
    @media(max-width: 1440px){
        padding-top: 60px;
    }
    @media(max-width: 1366px){
        padding-top: 50px;
    }
    @media(max-width: 1280px){
        padding-top: 40px;
    }
    @media(max-width: 1024px){
        padding-top: 0;
    }
    nav.navigation{
        ul{
            @extend .no-list;
            flex-direction: column;
            li{
                margin-bottom: 40px;
                @media(max-width: 1680px){
                    margin-bottom: 30px;
                }
                @media(max-width: 1440px){
                    margin-bottom: 25px;
                }
                @media(max-width: 1366px){
                    margin-bottom: 20px;
                }
                @media(max-width: 1280px){
                    margin-bottom: 15px;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                a{
                    color: $mfc;
                    font-size: 20px;
                    font-weight: 700;
                    @media(max-width: 1024px){
                        
                    }
                    &:hover{
                        color: $cwhite;
                    }
                    &.active{
                        color: $cwhite;
                    }
                }
            }
        }
    }
}
main.light{
    div.left_section_navigation{
        nav.navigation{
            ul{
                li{
                    a{
                        color: $cmain;
                        font-size: 20px;
                        font-weight: 700;
                        @media(max-width: 1024px){
                            
                        }
                        &:hover{
                            color: $cbase;
                        }
                        &.active{
                            color: $cbase;
                        }
                    }
                }
            }
        }
    }
}