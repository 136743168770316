@import "../../index.scss";
div.content{
    &.other_info {
        div.other_info_listing {
            display: grid;
            grid-gap: 50px;
            grid-template-columns: repeat(2, 1fr);
            @media (max-width: 1680px) {
            }
            @media (max-width: 1440px) {
                grid-gap: 40px;
            }
            @media (max-width: 1366px) {
            }
            @media (max-width: 1280px) {
                grid-gap: 30px;
            }
            @media (max-width: 1024px) {
                grid-gap: 15px;
            }
            @media (max-width: 768px) {
                grid-template-columns: 1fr;
            }
            @media (max-width: 420px) {
            }
            div.other_info_item {
                h3.other_info_item_title {
                    margin-bottom: 20px;
                    color: $cwhite;
                    font-size: 24px;
                    font-weight: 700;
                    @media (max-width: 1680px) {
                        font-size: 22px;
                    }
                    @media (max-width: 1024px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }
                ul.block_desc {
                    @extend .no-list;
                    display: grid;
                    row-gap: 10px;
                    grid-template-columns: repeat(2, 200px);
                    @media (max-width: 1680px) {
                    }
                    @media (max-width: 1440px) {
                        grid-template-columns: repeat(2, 180px);
                    }
                    @media (max-width: 1366px) {
                    }
                    @media (max-width: 1280px) {
                        grid-template-columns: repeat(2, 150px);
                    }
                    @media (max-width: 1024px) {
                        grid-template-columns: repeat(2, 130px);
                    }
                    @media (max-width: 768px) {
                        column-gap: 40px;
                    }
                    @media (max-width: 420px) {
                        column-gap: 20px;
                    }
                    li {
                        color: $textColor;
                        font-size: 18px;
                        font-weight: 400;
                        @media (max-width: 1680px) {
                            font-size: 16px;
                        }
                        @media (max-width: 1024px) {
                            font-size: 14px;
                        }
                    }
                }
                .skills {
                    .skills_list{
                        @extend .no-list;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 15px;
                        @media (max-width: 768px) {
                            grid-template-columns: 1fr;
                            gap: 10px;
                        }
                        li{
                            display: flex;
                            align-items: center;
                            border: 1px solid $cbase;
                            progress {
                                display: flex;
                                align-items: center;
                                position: absolute;
                                background-color: rgba($cwhite, 0.1);
                                width: 100%;
                                height: 100%;
                                &::-webkit-progress-bar{
                                    background-color: $cdark;
                                }
                                &::-webkit-progress-value {
                                    background-color: rgba($cwhite, 0.1);
                                }
                                &::-moz-progress-bar {
                                    background-color: rgba($cwhite, 0.1);
                                }
                                &:after{
                                    content: attr(value)"%";
                                    position: absolute;
                                    right: 10px;
                                    color: $cwhite;
                                }
                            }
                            span {
                                width: 100%;
                                padding: 5px 40px 5px 10px;
                                font-size: 14px;
                                font-family: monospace;
                                color: $cwhite;
                                @media (max-width: 1024px) {
                                    font-size: 12px;
                                    line-height: 1.1;
                                }
                            }
                        }
                        &.no_bar{
                            display: flex;
                            gap: 10px;
                            flex-wrap: wrap;
                            li{
                                progress{
                                    display: none;
                                }
                                span{
                                    padding: 5px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .section_text{
          margin-bottom: 30px;
          color: $cwhite;
          font-size: 18px;
        }
    }
}
main.light{
    div.content{
        &.other_info {
          .section_text{
            color: $cbase;
          }
          div.other_info_listing {
              div.other_info_item {
                  h3.other_info_item_title {
                      color: $cmain;
                  }
                  ul.block_desc {
                      li {
                          color: $cbase;
                      }
                  }
                  .skills {
                      .skills_list{
                          li{
                              border: 1px solid $cbase;
                              progress {
                                  background-color: rgba($cbase, 0.4);
                                  &::-webkit-progress-bar{
                                      background-color: $cwhite;
                                  }
                                  &::-webkit-progress-value {
                                      background-color: rgba($cbase, 0.4);
                                  }
                                  &::-moz-progress-bar {
                                      background-color: rgba($cbase, 0.4);
                                  }
                                  &:after{
                                      color: $cbase;
                                  }
                              }
                              span {
                                  color: $cbase;
                              }
                          }
                      }
                  }
              }
          }
        }
    }
}